import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout/layout";
import Seo from "../components/Seo/seo";
import * as styles from "./tag.module.css";
import "@fontsource/lato";
import { TagLink } from "../components/PostLinks/post-links";

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const postLinks = posts.map((post) => (
      <div className={styles.tagLink} key={post.node.frontmatter.path}>
        {/* <Link to={post.node.frontmatter.path}>
          {post.node.frontmatter.title}
        </Link> */}
        <TagLink key={post.node.frontmatter.path} post={post.node} />
      </div>
    ));
    const tag = this.props.pageContext.tag;
    const totalCount = this.props.data.allMarkdownRemark.totalCount;
    const tagHeader = ` ( ${totalCount} Post${totalCount === 1 ? "" : "s"}  )`;
    return (
      <Layout>
        <Seo title="Tag" />
        <div style={{ marginBottom: "6rem" }}>
          <h2 className={styles.tagHeader}>
            {tag}
            {"  "}
            <span className={styles.small}> {tagHeader}</span>
          </h2>
          <div className={styles.taglist}>{postLinks}</div>
          <p>
            <Link className={styles.allTag} to="/tags/">
              Browse All Tags
            </Link>
          </p>
        </div>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, language: { eq: "en" } } }
    ) {
      totalCount
      edges {
        node {
          timeToRead
          frontmatter {
            title
            path
            thumbnail
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
